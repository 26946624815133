import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";

interface IPropsModal {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  title: string | JSX.Element;
}

export function DeleteModal({ open, onClose, onDelete, title }: IPropsModal) {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" mb={2}>
          Confirmer la suppression
        </Typography>
        <Typography variant="body1" mb={3}>
          {title}
        </Typography>
        <Box display="flex" justifyContent="space-between">
          <Button variant="contained" color="error" onClick={onDelete}>
            Supprimer
          </Button>
          <Button variant="outlined" onClick={onClose}>
            Annuler
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
