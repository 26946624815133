import React, { useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import "./ShopDataGrid.css";
import { useNavigate } from "react-router-dom";
import { Grid, IconButton } from "@mui/material";
import { User } from "../model/User";
import { deleteUser } from "../service/UserService";
import DeleteIcon from "@mui/icons-material/Delete";
import { DeleteModal } from "./DeleteModal";

export function UserDataGrid({
  users,
  setDeleteUser,
}: {
  users: User[];
  setDeleteUser: (deleteUser: boolean) => void;
}) {
  const navigate = useNavigate();
  const rows = users.map((user) => ({
    id: user.uid,
    ...user,
  }));

  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const handleOpen = (user: User) => {
    setSelectedUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedUser(null);
  };

  const handleDelete = async () => {
    if (selectedUser) {
      await deleteUser(selectedUser.uid);
      handleClose();
      setDeleteUser(true);
    }
  };
  const columns: GridColDef[] = [
    { field: "uid", headerName: "ID", flex: 1 },
    {
      field: "firstName",
      headerName: "Prénom",
      flex: 1,
    },
    {
      field: "lastName",
      headerName: "Prénom",
      flex: 1,
    },
    { field: "managerEmail", headerName: "Email", flex: 1 },
    {
      field: "managerPhone",
      headerName: "Téléphone",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const { managerPhone } = params.row;
        return <span>{`${managerPhone}`}</span>;
      },
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <IconButton
          color="error"
          onClick={(event) => {
            event.stopPropagation();
            handleOpen(params.row);
          }}
          aria-label="delete"
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];
  const handleRowClick = (param: any) => {
    navigate(`/user/edit/${param.id}`);
  };

  return (
    <>
      <Grid style={{ height: "calc(100vh - 64px)", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSizeOptions={[5, 10, 20]}
          disableRowSelectionOnClick
          initialState={{
            pagination: { paginationModel: { pageSize: 20 } },
          }}
          sx={{
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "primary.main",
            },
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
          onRowClick={handleRowClick}
        />
      </Grid>
      <DeleteModal
        open={open}
        onClose={handleClose}
        onDelete={handleDelete}
        title={
          <>
            Voulez-vous vraiment supprimer{" "}
            <strong>
              {selectedUser?.firstName} {selectedUser?.lastName}
            </strong>{" "}
            ?
          </>
        }
      />
    </>
  );
}
