import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { Deal } from "../../model/Deal";
import { LogoUploader } from "../ShopEditor/LogoUploader";

interface DealEditorProps {
  deal: Deal;
  onClose: () => void;
  onSave: (deal: Deal) => void;
}

function toISOStringWithOffset(date: Date, offsetInHours: number): string {
  const offsetInMs = offsetInHours * 60 * 60 * 1000;
  const localDate = new Date(date.getTime() + offsetInMs);
  const isoString = localDate.toISOString().replace("Z", "");
  const offsetSign = offsetInHours >= 0 ? "+" : "-";
  const absOffsetHours = Math.abs(offsetInHours).toString().padStart(2, "0");
  const offset = `${offsetSign}${absOffsetHours}:00`;
  return `${isoString}${offset}`;
}

export function DealEditor({ deal, onClose, onSave }: DealEditorProps) {
  const [formData, setFormData] = useState<Deal>({ ...deal });
  const [error, setError] = useState<boolean>(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleCategoriesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const categories = value.split(",").map((category) => category.trim());
    setFormData((prevData) => ({
      ...prevData,
      categories: categories,
    }));
  };

  const handleTagsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const tags = value.split(",").map((tag) => tag.trim());
    setFormData((prevData) => ({
      ...prevData,
      tags: tags,
    }));
  };
  const handleDateChange =
    (name: "createdAt" | "startDate" | "endDate") =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const date = e.target.value ? new Date(e.target.value) : null;
      setFormData((prevData) => ({
        ...prevData,
        [name]: date,
      }));
    };
  const handleSave = () => {
    if (formData.title.trim() === "") {
      setError(true);
    } else {
      setError(false);
      onSave(formData);
    }
  };
  const handleDrop = (acceptedFiles: File[]) => {
    setFormData({ ...formData, imageFile: acceptedFiles[0] } as Deal);
  };
  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Modifier la promotion</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  name="active"
                  checked={formData.active}
                  onChange={handleChange}
                />
              }
              label="Active"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="title"
              label="Titre"
              value={formData.title}
              onChange={handleChange}
              fullWidth
              required
              helperText={error ? "Ce champ est obligatoire" : ""}
              error={error}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="description"
              label="Description"
              value={formData.description}
              onChange={handleChange}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="categories"
              label="Catégories (séparées par des virgules)"
              value={formData.categories.join(", ")}
              onChange={handleCategoriesChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="tags"
              label="Tags (séparés par des virgules)"
              value={formData.tags.join(", ")}
              onChange={handleTagsChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="startDate"
              label="Date de début"
              type="datetime-local"
              value={
                formData.startDate
                  ? toISOStringWithOffset(formData.startDate, 2).slice(0, 16)
                  : ""
              }
              onChange={handleDateChange("startDate")}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="endDate"
              label="Date de fin"
              type="datetime-local"
              value={
                formData.endDate
                  ? toISOStringWithOffset(formData.endDate, 2).slice(0, 16)
                  : ""
              }
              onChange={handleDateChange("endDate")}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <LogoUploader
              logo={
                formData.imageFile !== undefined
                  ? URL.createObjectURL(formData.imageFile)
                  : formData.image!
              }
              onDrop={handleDrop}
              title={"Image de la promotion"}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <Button onClick={handleSave} color="primary">
          Enregistrer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
